'use client'

import { useState } from 'react'

import { MainLayout } from '../components/layout/MainLayout/MainLayout'
import { isFeatureEnabled } from '../helpers/featureFlags'
import {
  BackupPolicyIcon,
  ClusterIcon,
  ComputingIcon,
  ContainerIcon,
  DashboardIcon,
  DiskIcon,
  DiskSnapshotIcon,
  ImageIcon,
  NetworkIcon,
  ProjectIcon,
  StorageIcon,
  VirtualMachineIcon,
} from '../helpers/icons'
import { useSignOut } from '../hooks/useHandleAuthState'
import { useHighestGroup } from '../hooks/useHighestGroup'
import { useTranslation } from '../i18n/useTranslation'
import { useUserStore } from '../store/user'

import { SettingsDialog } from './SettingsDialog'

export const MainLayoutFilled = ({
  version,
  children,
}: {
  version?: string
  children: React.ReactNode
}) => {
  const { t } = useTranslation()

  const { user } = useUserStore()
  const role = useHighestGroup()
  const signOutUser = useSignOut()

  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false)

  const menuItems = [
    {
      name: t.navigation.dashboard,
      href: '/',
      icon: <DashboardIcon />,
    },
    {
      name: t.navigation.projects,
      href: '/projects',
      icon: <ProjectIcon />,
    },
    {
      name: t.navigation.computing,
      icon: <ComputingIcon />,
      childItems: [
        ...(isFeatureEnabled('clusters')
          ? [
              {
                name: t.navigation.clusters.title,
                href: '/clusters',
                icon: <ClusterIcon />,
              },
            ]
          : []),
        ...(isFeatureEnabled('containers')
          ? [
              {
                name: t.navigation.containers,
                href: '/containers',
                icon: <ContainerIcon />,
              },
            ]
          : []),
        {
          name: t.navigation.virtualMachines,
          href: '/virtual-machines',
          icon: <VirtualMachineIcon />,
        },
      ],
    },
    {
      name: t.navigation.storage,
      icon: <StorageIcon />,
      childItems: [
        { name: t.navigation.disks, href: '/disks', icon: <DiskIcon /> },
        { name: t.navigation.images, href: '/images', icon: <ImageIcon /> },
        ...(isFeatureEnabled('disk-snapshots')
          ? [
              {
                name: t.navigation.diskSnapshots,
                href: '/disk-snapshots',
                icon: <DiskSnapshotIcon />,
              },
            ]
          : []),
        ...(isFeatureEnabled('backup-policies')
          ? [
              {
                name: t.navigation.backupPolicies,
                href: '/backup-policies',
                icon: <BackupPolicyIcon />,
              },
            ]
          : []),
      ],
    },
    ...(isFeatureEnabled('networks')
      ? [
          {
            name: t.navigation.networks,
            href: '/networks',
            icon: <NetworkIcon />,
          },
        ]
      : []),
  ]

  return (
    <MainLayout
      menuItems={menuItems}
      version={version}
      user={user}
      role={role}
      signOutUser={signOutUser}
      openSettingsMenu={() => setSettingsMenuOpen(true)}
    >
      <SettingsDialog open={settingsMenuOpen} setOpen={setSettingsMenuOpen} />

      {children}
    </MainLayout>
  )
}
